import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReviewStyles from "../styles/reviewStyles"

const Reviews = () => (
  <Layout>
    <SEO title="Home" />
    <ReviewStyles>
      <h1>Restaurant review coming soon!!!</h1>
    </ReviewStyles>
  </Layout>
)

export default Reviews

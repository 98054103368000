import styled from "styled-components"

const ReviewStyles = styled("div")`
  height: 100vh;
  text-align: center;
  h1 {
    margin-top: 130px;
    padding: 10px;
  }
`

export default ReviewStyles
